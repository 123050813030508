import { useCallback, useEffect, useState } from "react";
import usePrompts from "../../hooks/usePrompts";
// import PromptCard from "../PromptCard/PromptCard";
// import PromptTagsContainer from "../PromptTagsContainer/PromptTagsContainer";

export default function PromptsContainer() {

    //STATE
    const [ allPrompts, setAllPrompts ] = useState( null )
    const [ displayPrompts, setDisplayPrompts ] = useState( null )
    const [ categories, setCategories ] = useState( null )
    const [ selectedCategories, setSelectedCategories ] = useState( [] )
    const [ expandCards, setExpandCards ] = useState( null )
    
    
    //HOOKS
    const { getAllPrompts } = usePrompts()

    //FUNCTIONS
    const getPrompts = useCallback( async () => {
        try {
            const data = await getAllPrompts()
            setAllPrompts( data )
        } catch ( error ) {
            console.log( error );
        }
    }, [ getAllPrompts ] )

    const getUniqueCategories = useCallback (( promptArray ) => {

        //GETS UNIQUE COMPANIES FOR ALL AVATARS
        const uniqueCategories = []

        promptArray.forEach( prompt => {

            const promptCategory = prompt.category.toLowerCase() 
            if( ! uniqueCategories.includes( promptCategory ) ){
                uniqueCategories.push( promptCategory )
            }
        })
        setCategories( uniqueCategories )

    }, [ ] )

    const handlePromptTag = ( category ) => {
        if( !selectedCategories.includes( category ) ){
            setSelectedCategories([...selectedCategories, category ])
        } else {
            const updatedArray = selectedCategories.filter( item => item !== category )
            setSelectedCategories( updatedArray )
        }
    }

    const filterPromtsByCategory = useCallback(() => {
        const promptArray = []
        selectedCategories.forEach(( category ) => {
            allPrompts.forEach(( prompt ) => {
                if( prompt.category.toLowerCase() === category ){
                    promptArray.push( prompt )
                }
            })  
        })
        return promptArray

    }, [ selectedCategories, allPrompts ])

    //EFFECTS
    useEffect(() => {

        getPrompts()

    }, [ getPrompts ])

    useEffect(() => {

        if( allPrompts?.length > 0 ){
            setDisplayPrompts( allPrompts )
            getUniqueCategories( allPrompts )
        }

    }, [ allPrompts, getUniqueCategories ])

    useEffect(() => {
        if( selectedCategories.length > 0 ){
            //FILTER PROMPTS BY CATEGORY
            const filteredPromptArray = filterPromtsByCategory()
            setDisplayPrompts( filteredPromptArray )

        } else {
            setDisplayPrompts( allPrompts )
        }
    }, [ selectedCategories, allPrompts, filterPromtsByCategory ])

    return(
        <div className="prompts main-container">
            <div className="no-data-message">
                <h3>We are building this section</h3>
                <p>Stay tuned for updates!</p>
            </div>
            {/* <PromptTagsContainer categories={ categories } selectedCategories={ selectedCategories } handlePromptTag={ handlePromptTag } />
            <div className="toggle-btn-container">
                <p className={ expandCards ? 'inactive' : ''}>Preview</p>
                <button className={`toggle-btn ${ expandCards ? 'active': '' }`}  onClick={() => setExpandCards( !expandCards )}>
                    <span></span>
                </button>
                <p className={ !expandCards ? 'inactive' : ''}>Exapand Prompts</p>
            </div>
            <div className="prompt-cards-container">
                {
                    displayPrompts?.length > 0 && displayPrompts.map(( prompt, idx ) => {
                        return <PromptCard prompt={ prompt } key={ idx } expandCards={ expandCards }/>
                    })
                }

            </div> */}
            
        </div>
    )
}