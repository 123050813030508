import { useState, useContext, useEffect } from "react";
import Loader from "../Loader/Loader";
import { AppContext } from "../../context/AppContext";
import Swal from "sweetalert2";
import BackBtn from "../BackBtn/BackBtn";
import { useNavigate } from "react-router-dom";
import useProducts from "../../hooks/useProducts";

export default function MembershipContainer() {

    //STATE
    const [ isLoading, setIsLoading ] = useState( true )

    //HOOKS
    const { createCheckoutSession } = useProducts()

    //ROUTER
    const navigate = useNavigate()

    //CONTEXT
    const { darkMode, globalAccount } = useContext( AppContext )

    //FUNCTIONS
    const handleCheckoutSession = async ( productId ) => {
        try {
            setIsLoading( true )
            const accountId = globalAccount.id
            const sessionUrl = await createCheckoutSession( accountId, productId )
            setIsLoading( false )
            window.location.href = sessionUrl
            
        } catch (error) {
            Swal.fire({
                title: 'Oops!',
                text: error.message,
                icon: 'warning',
                timer: 2500,
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }


    //EFFECTS
    useEffect(() => {
        if ( globalAccount ){
            setIsLoading( false )
        }
    }, [  globalAccount ])


    return(
        <div className={`credits main-container ${ !darkMode ? 'light' : '' }`}>
            <BackBtn action={ ()=> navigate('/settings') }/>
            <div className="inner-container round-div div-shadow">
                {   isLoading ? 
                    <Loader/>
                    :
                    <>
                        <h4>Memberships & Pricing</h4>
                        <div className="product-container">
                            
                            <div className={`product-card round-div ${ !globalAccount.membership || globalAccount.membership === 'starter' ? 'current': ''}`}>
                                <h5>MarketSauce Studio</h5>
                                <div className="price-div">
                                    <p className="big">$20</p>
                                    <div className="detail">
                                        <p>/ monthly</p>
                                    </div>
                                </div>
                                <button className="product-purchase-btn" onClick={ ()=> handleCheckoutSession( 'aasbd82-6caa6-5a5a-5a5a-b0173e7c1a98' )}>Buy</button>
                                <ul>
                                    <li><span>1 Credit for Custom Blueprints</span></li>
                                    <li><span>Unlock Avatar Simulation Chat</span></li>
                                    <li><span>Exclusive Tools & Resources</span></li>
                                    <li><span>24/7 D.A.R.B. AI Chat Bot Access</span></li>
                                    <li><span>Email & Chat Support</span></li>
                                </ul>
                                {
                                    (!globalAccount.membership || globalAccount.membership === 'starter') &&
                                    <div className="current-marker-div">
                                        <p>Current Plan</p>
                                    </div>
                                }
                            </div>
                            <div className={`product-card round-div ${ globalAccount.membership === 'accelerator' ? 'current': ''}`}>
                                <h5>MarketSauce Accelerator</h5>
                                <div className="price-div">
                                    <p className="big">$250</p>
                                    <div className="detail">
                                        <p></p>
                                        <p>/ monthly</p>
                                    </div>
                                </div>
                                <button className="product-purchase-btn" onClick={ ()=> handleCheckoutSession( 'a003fu2v-11ac-x0fb-43b5-ge7071714391' )}>Buy</button>
                                <ul>
                                    <li><span>Everything in Studio</span></li>
                                    <li><span>Up to 10 Credits for Custom Blueprints (monthly)</span></li>
                                    <li><span>1 Strategic Creative Marketing Brief with Personalized Insights</span></li>
                                    <li><span>Credits Update Monthly</span></li>
                                    <li><span>Monthly Updates</span></li>
                                    <li><span>Priority Support</span></li>
                                </ul>
                                {
                                    (globalAccount.membership === 'accelerator') &&
                                    <div className="current-marker-div">
                                        <p>Current Plan</p>
                                    </div>
                                }
                            </div>
                            <div className={`product-card round-div ${ globalAccount.membership === 'agency' ? 'current': ''}`}>
                                <h5>MarketSauce Agency</h5>
                                <div className="price-div">
                                    <p className="big">$2,500</p>
                                    <div className="detail">
                                        <p></p>
                                        <p>/ monthly</p>
                                    </div>
                                </div>
                                <button className="product-purchase-btn" onClick={ ()=> handleCheckoutSession( 'cbbd5f84-6c36-4e4e-ae8a-b0173e7c1a26' )}>Buy</button>
                                <ul>
                                    <li><span>Up to 250 Credits for Custom Blueprints (monthly)</span></li>
                                    <li><span>Blueprint Credit Apply to Custom Projects</span></li>
                                    <li><span>12 Month Minimum Commitment</span></li>
                                    <li><span>Private VIP Hackathons</span></li>
                                    <li><span>Custom AI Integrations</span></li>
                                    <li><span>Ongoing VIP Client Support</span></li>
                                </ul>
                                {
                                    (globalAccount.membership === 'agency') &&
                                    <div className="current-marker-div">
                                        <p>Current Plan</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                    
                }

            </div>

        </div>
    )
}