import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
// import AdditionalSegmentsPreview from "../AdditionalSegmentsPreview/AdditionalSegmentsPreview";
import Loader from '../Loader/Loader'
import DetailHeadshotContainer from "../DetailHeadshotContainer/DetailHeadshotContainer";
// import DetailOptBar from "../DetailOptBar/DetailOptBar";
// import useAvatars from "../../hooks/useAvatars";
import AvatarDetailDoc from "../AvatarDetailDoc/AvatarDetailDoc";
import BackBtn from '../BackBtn/BackBtn'
import { Link, useNavigate, useParams } from "react-router-dom";
import useAvatars from "../../hooks/useAvatars";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faMessage } from "@fortawesome/free-solid-svg-icons";

export default function AvatarDetailContainer() {

    //STATES
    const [ chatArray, setChatArray ] = useState( [] )
    const [ isLoading, setIsLoading ] = useState( true )
    const [ targetAvatar, setTargetAvatar ] = useState( null )
    const [ targetId, setTargetId ] = useState( null )

    //HOOKS
    const { getAvatarById } = useAvatars()

    //CONTEXT
    // const { targetAvatar, userAvatars, darkMode } = useContext( AppContext )
    const { userAvatars, darkMode, globalAccount } = useContext( AppContext )

    //ROUTER
    const navigate = useNavigate()
    const { id } = useParams()

    //FUNCTIONS
    const handleBack = () => {
        navigate('/')
    }

    useEffect(()=> {

        if( targetAvatar ){
            // //TURNS LOADER OFF
            setIsLoading( false )
        }
    }, [ targetAvatar ])

    const getAvatarInfo = useCallback(async ( id ) => {
        
        try {
            const avatar = await getAvatarById( id )
            setTargetAvatar( avatar )

        } catch ( err) {
            Swal.fire({
                title: 'Oops!',
                text: err.message,
                icon: 'error',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }, [ getAvatarById ])

    //EFFECTS
    useEffect(() => {

        if ( id  ) {
            setTargetId( id )
        }   
    }, [ id ])

    useEffect(()=> {
        if( targetId){

            getAvatarInfo( targetId )
        }
    }, [targetId, getAvatarInfo])
 
    return(
        <div className="avatar-datail-container">
            { isLoading ?
                <Loader/>
            :
                <>
                    <div className="detail-sidebar-container">
                        <div className="upper-container">
                            <DetailHeadshotContainer avatar={ targetAvatar } darkMode={ darkMode } />
                            {/* <AdditionalSegmentsPreview array={ targetAvatar.additionalSegments } darkMode={ darkMode } desktop={ true }/> */}
                            {
                                globalAccount.membership === 'test' &&
                                <div className="chat-btn-container">
                                    <Link to={`/chat/avatar/${id}`}>
                                        <button className="btn main-btn round-btn ">    
                                            <FontAwesomeIcon  icon={ faComment }/>
                                        </button>
                                    </Link>
                                </div>

                            }
                        </div>
                    </div>
                    <AvatarDetailDoc avatar={ targetAvatar } />
                    <BackBtn action={ handleBack }/>
                </>
            }
        </div>
    )
}