import { useContext } from "react"
import { AppContext } from "../context/AppContext"
import GlobalLoader from "../components/GlobalLoader/GlobalLoader"
import SideBarContainer from "../components/SideBarContainer/SideBarContainer"
import BoardContainer from "../components/BoardContainer/BoardContainer"
import Login from "./Login"
import TopBarContainer from "../components/TopBarContainer/TopBarContainer"
import AccountInactiveMessage from "../components/AccountInactiveMessage/AccountInactiveMessage"
import CreditsContainer from "../components/CreditsContainer/CreditsContainer"
import MembershipContainer from "../components/MembershipContainer/MembershipContainer"


export default function Memberships() {
   
        //CONTEXT
        const { globalUser, globalLoader, globalAccount } = useContext(AppContext)

        return(
            <>
            { globalLoader ?
                <GlobalLoader/>
                :
                <>
                { !globalUser?
                    <Login/>
                    :
                    <div className='view-container'>
                        <SideBarContainer/>
                        <TopBarContainer/>
                        <BoardContainer sectionTitle={ 'Memberships' } faded={ false } section={ globalAccount && !globalAccount.isActive ?
                    <AccountInactiveMessage/> : <MembershipContainer/> } />
                    </div>
                }
                </>
            }
            </>
        )
}