import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AppProvider } from './context/AppContext';
import Library from './views/Library';
import Admin from './views/Admin';
import Create from './views/Create';
import AvatarDetail from './views/AvatarDetail';
import Community from './views/Community';
import Companies from './views/Companies';
import Team from './views/Team';
import Invite from './views/Invite';
import Settings from './views/Settings';
import Orders from './views/Orders';
import Chat from './views/Chat';
import ChatAvatar from './views/ChatAvatar';
import Reset from './views/Reset';
import NotFound from './views/NotFound';
import PrivacyPolicy from './views/PrivacyPolicy';
import Accounts from './views/Accounts';
import Users from './views/Users';
import OnboardingGuide from './views/OnboardingGuide';
import OnboardingContainer from './components/OnboardingContainer/OnboardingContainer';
import Prompts from './views/Prompts';
import Credits from './views/Credits';
import Success from './views/Success';
import Memberships from './views/Memberships';

function App() {
  return (
    <>
      <BrowserRouter>
        <AppProvider>
          <OnboardingContainer/>
          <Routes>
            <Route exact path='/' element={ <Library/> } />
            <Route exact path='/onboarding' element={ <OnboardingGuide/> } />
            <Route exact path='/admin' element={ <Admin/> } />
            <Route exact path='/admin/accounts' element={ <Accounts/> } />
            <Route exact path='/admin/users' element={ <Users/> } />
            <Route exact path='/settings' element={ <Settings/> } /> 
            <Route exact path='/settings/companies' element={ <Companies/> } />
            <Route exact path='/settings/credits' element={ <Credits/> } />
            <Route exact path='/settings/membership' element={ <Memberships/> } />
            <Route exact path='/settings/team' element={ <Team/> } />
            <Route exact path='/create' element={ <Create/> } />
            <Route exact path='/community' element={ <Community/> } />
            <Route exact path='/orders' element={ <Orders/> } />
            <Route exact path='/prompts' element={ <Prompts/> } />
            <Route exact path='/chat' element={ <Chat/> } />
            <Route exact path='/chat/avatar/:id' element={ <ChatAvatar/> } />
            <Route exact path='/payment_success' element={ <Success/>} />
            <Route path={`/avatarDetail/:id`} element={<AvatarDetail/>} />
            <Route path={`/invite/:id/:token`} element={<Invite/>} />
            <Route exact path='/reset' element={ <Reset/> } />
            <Route exact path='/privacy-policy' element={ <PrivacyPolicy/> } />
            <Route path="*" element={ <NotFound/> } />
          </Routes>
        </AppProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
