import { useContext, useEffect, useState } from "react"
import useOrders from "../../hooks/useOrders"
import { AppContext } from "../../context/AppContext"
import Swal from "sweetalert2"
import Loader from "../Loader/Loader"
import OrderItem from "../OrderItem/OrderItem"


export default function OrdersContainer() { 

    //STATE
    const [ orders, setOrders ] = useState( null )
    const [ isLoading, setIsLoading ] = useState( true )

    //CONTEXT
    const { globalUser } = useContext( AppContext )
    
    //HOOKS
    const { getAccountOrders } = useOrders()

    //FUNCTIONS 
    const toggleLoader = ( bool ) => {
        setIsLoading( bool )
    }

    //EFFECTS
    useEffect(() => {

        const getOrders = async ( accountId ) => {
            try {

                const accountOrders = await getAccountOrders( accountId )
                accountOrders.sort(( a, b ) => b.ordered - a.ordered ) 
                setOrders( accountOrders )

                //TURNS LOADER OFF
                toggleLoader( false )

            } catch ( err ) {


                Swal.fire({
                    title: 'Oops!',
                    text: err.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    buttonsStyling: false,
                    customClass: {
                        popup: 'marketsauce-alert-container round-div div-shadow',
                        icon: 'alert-icon',
                        confirmButton: 'confirm-btn btn order2',
                        denyButton: 'deny-btn btn order1',
                    }
                })
            }
        }
        if( globalUser ) {


            getOrders( globalUser.account )

        }

    }, [ globalUser, getAccountOrders ] )


    return (
        <div className={`orders main-container`}>
            <div className="inner-container round-div div-shadow">

                { isLoading ?
                    <Loader/>
                    :
                    <>
                        { orders && orders.length > 0 ?

                            <div className="orders-container">
                                <ul>
                                    <li className="header">
                                        <p className="item l">Company - Campaign</p>
                                        <p className="item m">Ordered</p>
                                        <p className="item s">Status</p>
                                        <p className="item xs">Link</p>
                                    </li>
                                    {
                                        orders.map(( order, idx ) => {
                                            return(
                                                <OrderItem order={ order } key={ idx }/> 
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            :
                            <div className="no-data-message">
                                <h3>You have no orders</h3>
                                <p>We could not find any orders for your account.</p>
                            </div>

                        }
                    </>
                }
            </div>
        </div>
    )

}